import { Map as map } from 'immutable';
import {
  SET_PROJECTIONS,
  SET_PROJECTION,
} from '../actionTypes';

import {Projection} from '../../src/models/projection';

const initialState = map({
  listProjections: [],
  projection: new Projection({}),
  page: 0,
  rowsPerPage: 5,
});

const projection = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECTIONS:
      return state.set('listProjections', action.payload);
    case SET_PROJECTION:
      return state.set('projection', action.payload);
    default:
      return state;
  }
};

export default projection;
