export class Addon {
  constructor(data) {
    this.id = data._id;
    this.name = data.name || '';
    this.status = data.status || false;
    this.demographic_aspects = data.demographic_aspects || [{
      price_by_age: 0,
      coverage: 0,
      min_age: 0,
      max_age: 0,
      sex: "M",
    }];
    this.description = data.description || '';
    this.payment_methods = data.payment_methods || [];
  }
}