export class Product {
  constructor(data) {
    this._id = data._id;
    this.recurrent_interval_base = data.recurrent_interval_base || '';
    this.payment_methods = data.payment_methods || [];
    this.status = data.status || true;
    this.addons = data.addons || [];
    this.name = data.name || '';
    this.shortName = data.shortName || '';
    this.country = data.country || '';
    this.countryCode = data.countryCode || '';
    this.options = data.options || [{ name: '', value: '' }];
    this.special_services = data.special_services || [{ name: '', value: '' }];
    this.surveyID = data.surveyID || 'default';
    this.logo = data.logo || null;
    this.termsFiles = data.termsFiles || null;
    this.validator_id = data.validator_id || '';
    this.price_base = data.price_base || '';
  }
}
