import { Map as map } from 'immutable';
import {
  SET_COMMISSIONS,
  SET_COMMISSION,
} from '../actionTypes';

import { Commission } from '../../src/models/commission';

const initialState = map({
  listCommissions: [],
  commission: new Commission({}),
  page: 0,
  rowsPerPage: 5,
});

const commission = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMISSIONS:
      return state.set('listCommissions', action.payload);
    case SET_COMMISSION:
      return state.set('commission', action.payload);
    default:
      return state;
  }
};

export default commission;
