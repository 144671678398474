export class Commission {
  constructor(data) {
    this._id = data._id;
    this.description = data.description || '';
    this.PERCENT_MATRIX = data.PERCENT_MATRIX || 0;
    this.PERCENT_MATRIX_FINANCING = data.PERCENT_MATRIX_FINANCING || 0;
    this.PERCENT_MATRIX_CP = data.PERCENT_MATRIX_CP || 0;
    this.PERCENT_MATRIX_FINANCING_CP = data.PERCENT_MATRIX_FINANCING_CP || 0;

    this.PERCENT_MATRIX_ALLY = data.PERCENT_MATRIX_ALLY || 0;
    this.PERCENT_MATRIX_AGENCY = data.PERCENT_MATRIX_AGENCY || 0;

    this.PERCENT_MATRIX_AGENCY_ALLY = data.PERCENT_MATRIX_AGENCY_ALLY || 0;
    this.PERCENT_MATRIX_AGENCY_ALLY_FINANCING = data.PERCENT_MATRIX_AGENCY_ALLY_FINANCING || 0;
    this.PERCENT_MATRIX_AGENCY_ALLY_CP = data.PERCENT_MATRIX_AGENCY_ALLY_CP || 0;
    this.PERCENT_MATRIX_AGENCY_ALLY_FINANCING_CP = data.PERCENT_MATRIX_AGENCY_ALLY_FINANCING_CP || 0;

    this.PERCENT_MASTER = data.PERCENT_MASTER || 0;
    this.PERCENT_MASTER_FINANCING = data.PERCENT_MASTER_FINANCING || 0;
    this.PERCENT_MASTER_CP = data.PERCENT_MASTER_CP || 0;
    this.PERCENT_MASTER_FINANCING_CP = data.PERCENT_MASTER_FINANCING_CP || 0;

    this.LOW = data.LOW || 0;
    this.LOW_VOLUME_MASTER = data.LOW_VOLUME_MASTER || 0;
    this.LOW_VOLUME_MASTER_FINANCING = data.LOW_VOLUME_MASTER_FINANCING || 0;
    this.LOW_VOLUME_MASTER_CP = data.LOW_VOLUME_MASTER_CP || 0;
    this.LOW_VOLUME_MASTER_FINANCING_CP = data.LOW_VOLUME_MASTER_FINANCING_CP || 0;
    this.LOW_VOLUME_ALLY = data.LOW_VOLUME_ALLY || 0;
    this.LOW_VOLUME_ALLY_FINANCING = data.LOW_VOLUME_ALLY_FINANCING || 0;
    this.LOW_VOLUME_ALLY_CP = data.LOW_VOLUME_ALLY_CP || 0;
    this.LOW_VOLUME_ALLY_FINANCING_CP = data.LOW_VOLUME_ALLY_FINANCING_CP || 0;

    this.MEDIUM = data.MEDIUM || 0;
    this.MEDIUM_VOLUME_MASTER = data.MEDIUM_VOLUME_MASTER || 0;
    this.MEDIUM_VOLUME_MASTER_FINANCING = data.MEDIUM_VOLUME_MASTER_FINANCING || 0;
    this.MEDIUM_VOLUME_MASTER_CP = data.MEDIUM_VOLUME_MASTER_CP || 0;
    this.MEDIUM_VOLUME_MASTER_FINANCING_CP = data.MEDIUM_VOLUME_MASTER_FINANCING_CP || 0;
    this.MEDIUM_VOLUME_ALLY = data.MEDIUM_VOLUME_ALLY || 0;
    this.MEDIUM_VOLUME_ALLY_FINANCING = data.MEDIUM_VOLUME_ALLY_FINANCING || 0;
    this.MEDIUM_VOLUME_ALLY_CP = data.MEDIUM_VOLUME_ALLY_CP || 0;
    this.MEDIUM_VOLUME_ALLY_FINANCING_CP = data.MEDIUM_VOLUME_ALLY_FINANCING_CP || 0;

    this.HIGH = data.HIGH || 0;
    this.HIGH_VOLUME_MASTER = data.HIGH_VOLUME_MASTER || 0;
    this.HIGH_VOLUME_MASTER_FINANCING = data.HIGH_VOLUME_MASTER_FINANCING || 0;
    this.HIGH_VOLUME_MASTER_CP = data.HIGH_VOLUME_MASTER_CP || 0;
    this.HIGH_VOLUME_MASTER_FINANCING_CP = data.HIGH_VOLUME_MASTER_FINANCING_CP || 0;
    this.HIGH_VOLUME_ALLY = data.HIGH_VOLUME_ALLY || 0;
    this.HIGH_VOLUME_ALLY_FINANCING = data.HIGH_VOLUME_ALLY_FINANCING || 0;
    this.HIGH_VOLUME_ALLY_CP = data.HIGH_VOLUME_ALLY_CP || 0;
    this.HIGH_VOLUME_ALLY_FINANCING_CP = data.HIGH_VOLUME_ALLY_FINANCING_CP || 0;

    this.status = data.status;
  }
}
