import { Map as map } from 'immutable';
import {
  SIGNIN,
  SIGNIN_ALLY,
  LOGOUT,
  USERUPDATE,
  CAMPAIGNS,
  SET_OPEN_DRAWER
} from '../actionTypes';

const isServer = typeof window === 'undefined';
let initialState = map({});
if (!isServer) {
  initialState = map({
    token: localStorage.getItem('token'),
    country: localStorage.getItem('country'),
    city: localStorage.getItem('city'),
    sex: localStorage.getItem('sex'),
    full_name: localStorage.getItem('full_name'),
    company_name: localStorage.getItem('company_name'),
    username: localStorage.getItem('username'),
    email: localStorage.getItem('email'),
    sessionDate: localStorage.getItem('sessionDate'),
    id_user: localStorage.getItem('id_user'),
    speciality: localStorage.getItem('speciality'),
    cell_phone: localStorage.getItem('cell_phone'),
    type: localStorage.getItem('type'), // user_type
    payments: localStorage.getItem('payments') === "true" || localStorage.getItem('payments') === true ? true : false,
    //products: localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [],
    logged: (localStorage.getItem('logged') && localStorage.getItem('username') && localStorage.getItem('type') && localStorage.getItem('id_user')) ? true : false,
    coupon: localStorage.getItem('coupon'),
    percent_off: localStorage.getItem('percent_off'),
    no_offers_coupons: JSON.parse(localStorage.getItem('no_offers_coupons')),
    no_aply_commission: JSON.parse(localStorage.getItem('no_aply_commission')),
    no_agent: JSON.parse(localStorage.getItem('no_agent')),
    no_agency: JSON.parse(localStorage.getItem('no_agency')),
    great_ally_id: localStorage.getItem('great_ally_id'),
    open_drawer: localStorage.getItem('open_drawer') || true,
    is_promoter: localStorage.getItem('is_promoter') === "true" || localStorage.getItem('is_promoter') === true ? true : false,
  })
}

function isLogin(state = initialState, action) {
  switch (action.type) {
    case SIGNIN:
      localStorage.clear();

      localStorage.setItem('id_user', action.payload.values._id);
      localStorage.setItem('type', action.payload.values.user_type);
      localStorage.setItem('full_name', action.payload.values.first_name + ' ' + action.payload.values.last_name);
      localStorage.setItem('username', action.payload.values.username);
      localStorage.setItem('email', action.payload.values.email);
      localStorage.setItem('sessionDate', new Date());
      localStorage.setItem('logged', true);
      localStorage.setItem('country', action.payload.values.country);
      localStorage.setItem('city', action.payload.values.city);
      localStorage.setItem('speciality', action.payload.values.speciality);
      localStorage.setItem('sex', action.payload.values.sex);
      localStorage.setItem('token', action.payload.values.token);
      localStorage.setItem('products', JSON.stringify(action.payload.values.products));
      localStorage.setItem('payments', action.payload.values.payments);

      return state.set('id_user', action.payload.values._id)
        .set('type', action.payload.values.user_type)
        .set('full_name', action.payload.values.first_name + ' ' + action.payload.values.last_name)
        .set('username', action.payload.values.username)
        .set('email', action.payload.values.email)
        .set('sessionDate', new Date())
        .set('logged', true)
        .set('country', action.payload.values.country)
        .set('city', action.payload.values.city)
        .set('speciality', action.payload.values.speciality)
        .set('sex', action.payload.values.sex)
        .set('token', action.payload.values.token)
        .set('products', action.payload.values.products)
        .set('payments', action.payload.values.payments);

    case SIGNIN_ALLY:
      localStorage.clear();
      localStorage.setItem('id_user', action.payload.values._id);
      localStorage.setItem('type', action.payload.values.user_type);
      localStorage.setItem('full_name', action.payload.values.first_name + ' ' + action.payload.values.last_name);
      localStorage.setItem('company_name', action.payload.values.company_name);
      localStorage.setItem('username', action.payload.values.username);
      localStorage.setItem('share_link', action.payload.values.share_link);
      localStorage.setItem('share_link_discount', action.payload.values.share_link_discount);
      localStorage.setItem('email', action.payload.values.email);
      localStorage.setItem('logged', true);
      localStorage.setItem('speciality', action.payload.values.speciality);
      localStorage.setItem('sex', action.payload.values.sex);
      localStorage.setItem('token', action.payload.values.token);
      localStorage.setItem('products', JSON.stringify(action.payload.values.products));
      localStorage.setItem('payments', action.payload.values.payments);
      localStorage.setItem('no_offers_coupons', action.payload.values.no_offers_coupons);
      localStorage.setItem('no_aply_commission', action.payload.values.no_aply_commission);
      if (action.payload.values.great_ally_id) { localStorage.setItem('great_ally_id', action.payload.values.great_ally_id); }
      localStorage.setItem('no_agency', action.payload.values.no_agency);
      localStorage.setItem('no_agent', action.payload.values.no_agent);
      localStorage.setItem('is_promoter', action.payload.values.is_promoter);

      return state.set('id_user', action.payload.values._id)
        .set('type', action.payload.values.user_type)
        .set('full_name', action.payload.values.first_name + ' ' + action.payload.values.last_name)
        .set('username', action.payload.values.username)
        .set('company_name', action.payload.values.company_name)
        .set('share_link', action.payload.values.share_link)
        .set('share_link_discount', action.payload.values.share_link_discount)
        .set('email', action.payload.values.email)
        .set('sessionDate', new Date())
        .set('logged', true)
        .set('country', action.payload.values.country)
        .set('city', action.payload.values.city)
        .set('speciality', action.payload.values.speciality)
        .set('sex', action.payload.values.sex)
        .set('token', action.payload.values.token)
        .set('products', action.payload.values.products)
        .set('payments', action.payload.values.payments)
        .set('no_offers_coupons', action.payload.values.no_offers_coupons)
        .set('no_aply_commission', action.payload.values.no_aply_commission)
        .set('great_ally_id', action.payload.values.great_ally_id)
        .set('no_agent', action.payload.values.no_agent)
        .set('no_agency', action.payload.values.no_agency)
        .set('is_promoter', action.payload.values.is_promoter);

    case LOGOUT:
      localStorage.clear();
      return state.set("logged", false)
        .set('id_user', null)
        .set('type', null)
        .set('full_name', null)
        .set('username', null)
        .set('email', null)
        .set('sessionDate', null)
        .set('country', null)
        .set('city', null)
        .set('speciality', null)
        .set('sex', null)
        .set('token', null)
        .set('products', null)
        .set('payments', null)
        .set('is_promoter', null);

    case USERUPDATE:
      return state.set("email", action.payload.email);

    case CAMPAIGNS:
      localStorage.setItem('products', JSON.stringify(action.payload.values.products));
      return state.set('products', action.payload.values.products);

    case SET_OPEN_DRAWER:
      localStorage.setItem('open_drawer', JSON.stringify(action.payload));
      return state.set('open_drawer', action.payload);

    default:
      return state
  }
}

export default isLogin