import { Map as map } from 'immutable';
import {UPDATE_MANAGED_USER, RESET_MANAGED_USER} from "../actionTypes";

const initialState = map({
  managedUser: {
    username: '',
    email: '',
    password: '',
    document_type: '',
    key: '',
    first_name: '',
    last_name: '',
    sex: '',
    dni: '',
    user_type: '',
    status: true,
    city: '',
    country: '',
    address:'',
    phone_one:'',
    speciality: '',
    first_time: '',
    products: [],
    payment_data: [],
    payments: false,
    deleted: '',
    ally_master_id: '',
    commission_id: '',
    birth_date: null,
    no_agency: true,
    no_offers_coupons: true,
    no_agent: true,
  }
});

function users(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MANAGED_USER:
      return state.set('managedUser', action.payload);
    case RESET_MANAGED_USER:
      return state.set('managedUser', {
        username: '',
        email: '',
        password: '',
        document_type: '',
        key: '',
        first_name: '',
        last_name: '',
        sex: '',
        dni: '',
        user_type: '',
        status: true,
        city: '',
        country: '',
        address:'',
        phone_one:'',
        speciality: '',
        first_time: '',
        products: [],
        payment_data: [],
        payments: false,
        deleted: '',
        ally_master_id: '',
        commission_id: '',
        birth_date: null,
        no_agency: true,
        no_offers_coupons: true,
        no_agent: true,
      });
    default:
      return state;
  }
}

export default users;
