import { Map as map } from 'immutable';
import { SET_BIGBRODERS_CANDIDATE, SET_BIGBRODERS, SET_BIGBRODER } from '../actionTypes';
import {Bigbroder} from '../../src/models/bigbroder';

const initialState = map({
  listBigbroders: [],
  listBigbrodersCandidate: [],
  bigbroder: new Bigbroder({}),
  page: 0,
  rowsPerPage: 5,
});

const bigbroder = (state = initialState, action) => {
  switch (action.type) {
    case SET_BIGBRODERS_CANDIDATE:
      return state.set('listBigbrodersCandidate', action.payload);
    case SET_BIGBRODERS:
      return state.set('listBigbroders', action.payload);
    case SET_BIGBRODER:
      return state.set('bigbroder', action.payload);
    default:
      return state;
  }
};

export default bigbroder;
