export class Promoter {
  aux = new Date();
  constructor(data) {
    this._id = data._id;
    this.promoterId = data.promoterId;
    this.full_name = data.full_name;
    this.active = data.active;
    this.nicknames = data.nicknames || [];
    this.commision = data.commision || 0;
    this.coupons = data.coupons ||
      [{
        id: '',
        percent_off: 0,
        duration: '',
        duration_in_months: undefined,
        max_redemptions: 0,
        redeem_by: this.aux.setDate(this.aux.getDate() + 1),
      }];
  }
}