const SITE_KEY = process.env.REACT_APP_REACAPTCHA_V3_KEY;
const ID = "recaptcha-key";

const loadScriptByURL = (callback) => {
    const url = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;
    const isScriptExist = document.getElementById(ID);

    if (!isScriptExist) {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = ID;
        script.onload = function () {
            if (callback) callback();
        };
        document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
}

export function loadRecaptchaV3() {
    loadScriptByURL(() => {
        console.log("recaptcha loaded!");
    });
}

export const getTokenReacaptcha = async (method) => {
    const action = { action: 'submit' };
    if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, action).then(async (recaptcha) => {
                method(recaptcha);
            });
        });
    } else { // to solve recaptcha bug in very slow connections
        loadScriptByURL(() => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(SITE_KEY, action).then(async (recaptcha) => {
                    method(recaptcha);
                });
            });
        });
    }
}

export function removeRecaptchaV3() {
    const isScriptExist = document.getElementById(ID);

    if (isScriptExist) {
        isScriptExist.parentNode.removeChild(isScriptExist);
    }

    const recaptchaElems = document.getElementsByClassName("grecaptcha-badge");
    if (recaptchaElems.length) {
        recaptchaElems[0].remove();
    }
}