import { Map as map } from 'immutable';
import { SAVETITULARDATA, SAVEBENEFICIARIESDATA, SAVE_QUOTATION_DATA, SAVE_QUOTATION_CLIENT, CLEAN_QUOTATION } from '../actionTypes';
import BaseTitular from '../../src/components/utils/BaseTitular';

const initialState = map({
  beneficiaries: [],
  quotation: {
    country: 'Venezuela',
    city: 'default',
    amount: 'default',
    percent_off: 'default',
    parients: [],
  },
  client: {},
  titular_client: {...BaseTitular}
})

function customer (state = initialState, action) {
  switch (action.type) {
    case SAVETITULARDATA:
      const { value } = action.payload.values.data;
      return state.set('titular_client', value);

    case SAVEBENEFICIARIESDATA:
      return state.set('beneficiaries', action.payload.values.beneficiaries)

    case SAVE_QUOTATION_DATA:
      return state.set('quotation', action.payload.values.quotation)

    case SAVE_QUOTATION_CLIENT:
      return state.set('client', action.payload.values.client)
    
    case CLEAN_QUOTATION:
      return state.set('client', {})
                  .set('quotation', {
                    country: 'Venezuela',
                    city: 'default',
                    amount: 'default',
                    percent_off: 'default',
                    parients: [],
                  });

    default:
      return state
  }
}

export default customer