export class Voluntary {
  constructor(data) {
    this._id = data._id;
    this.voluntaryID = data.voluntaryID;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.document_type = data.document_type || 'default';
    this.doc_dni = data.doc_dni || null;
    this.dni = data.dni;
    this.birth_date = data.birth_date || null;
    this.email = data.email;
    this.cell_phone = data.cell_phone;
    this.country = data.country || 'default';
    this.city = data.city;
    this.specialty = data.specialty || 'default';
    this.msds = data.msds;
    this.medical_college = data.medical_college;
    this.doc_medical_title = data.doc_medical_title || null;
    this.video = data.video || null;
    this.accept_terms = data.accept_terms || false;
    this.policy_privacy = data.policy_privacy || false;
    this.verify_number = data.verify_number || '';
    this.agree_share_video = data.agree_share_video || false;
  }
}