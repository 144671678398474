import { Map as map } from 'immutable';
import {
  SET_POINTS,
  SET_POINT,
} from '../actionTypes';

import { Point } from '../../src/models/point';

const initialState = map({
  listPoints: [],
  point: new Point({}),
  page: 0,
  rowsPerPage: 5,
});

const points = (state = initialState, action) => {
  switch (action.type) {
    case SET_POINTS:
      return state.set('listPoints', action.payload);
    case SET_POINT:
      return state.set('point', action.payload);
    default:
      return state;
  }
};

export default points;