export default {
  first_name: '',
  last_name: '',
  dni: '',
  nationality: 'default',
  email: '',
  phone_one: '',
  country: 'default',
  state: '',
  city: '',
  zip_zone: '',
  oath_statement: false,
  payment_method: 'default',
  payment_form: '',
  payment_interval: 'default',
  acceptTerms: false,
  // app_benefits: false,
  token: null,
  payment_voucher: null,
  reference_number: '',
  coupon: '',
  formErrors: {},
  client_card: {
    number: '',
    exp: '',
    cvc: '',
  },
  totalPayment: 0,
  total_currency: 0,
  currency: '',
  payment_methods:[],
  DIP: false,
}