import { Map as map } from 'immutable';
import {
  CLEAN_PROPOSAL,
  GET_PROPOSALS,
  RESET_CURRENT_CAMPAIGN,
  SET_PROPOSAL,
  UPDATE_CURRENT_CAMPAIGN
} from '../actionTypes';
import {Proposal} from '../../src/models/proposal';

const initialState = map({
  campaign: {
    campaign_tag: '',
    medium: '',
    template: '',
    data: null,
    estimated_population: '',
    landing: '',
    launch_time: null,
    status: '',
    parent: null,
    proporsal_id: '',
  },
  proposal: new Proposal({}),
  listProposals: [],
});

function marketing(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CURRENT_CAMPAIGN:
      return state.set('campaign', action.payload);
    case RESET_CURRENT_CAMPAIGN:
      return state.set('campaign', {
        campaign_tag: '',
        medium: '',
        template: '',
        data: null,
        estimated_population: '',
        landing: '',
        launch_time: null,
        status: '',
        parent: null,
        proporsal_id: ''
      });
    case GET_PROPOSALS:
      return state.set('listProposals', action.payload);
    case SET_PROPOSAL:
      return state.set('proposal', new Proposal(action.payload));
    case CLEAN_PROPOSAL:
      return state.set('proposal', new Proposal({}));
    default:
      return state;
  }
}

export default marketing;
