const base = [
  {country: 'venezuela', total: 0},
  {country: 'mexico', total: 0},
  {country: 'rep. dominicana', total: 0},
];

export class Projection {
  constructor(data) {
    this._id = data._id;
    this.period_name = data.period_name;
    this.start_date = data.start_date || null;
    // this.projections = data.projections ? [...base, ...data.projections] : base;
    this.projections = data.projections
      ? base.map((item, i) => Object.assign({}, item, data.projections[i]))
      : base;
  }
}
