export class Validator {
  constructor(data) {
    this._id = data._id;
    this.branch = data.branch || '';
    this.policy_type = data.policy_type || '';
    this.policy_category = data.policy_category || '';
    this.insurance_company = data.insurance_company || '';
    this.insurance_code = data.insurance_code || '';
    this.name = data.name || '';
  }
}