import { Map as map } from 'immutable';
import {
  GET_LEADS,
  SAVE_NEW_LEAD,
  CLEAN_NEW_LEAD,
  SAVE_LEAD_EMAIL,
  CLEAN_AFFILIATION,
  SAVE_ORIGIN_LEAD,
  SAVE_STATUS_LEAD,
} from '../actionTypes';

const isServer = typeof window === 'undefined';
const initialState = map({
  newLead: {
    created_at: null,
    first_name: '',
    last_name: '',
    origin: 'default',
    country: 'default',
    phone_one: '',
    email: '',
    city_interest: 'default',
    other_city: '',
    status: 'default',
    tracking: [
      {
        comment: "",
        action: "",
        formErrors: []
      },
    ]
  },
  leads: [],
  lead_email: !isServer ? localStorage.getItem('lead_email') : '',
  originFilter: [],
  statusFilter: [],
});

const leads = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEADS:
      return state.set('leads', action.payload.values);

    case SAVE_NEW_LEAD:
      return state.set('newLead', action.payload.values.newLead);

    case SAVE_ORIGIN_LEAD:
      return state.set('originFilter', action.payload);

    case SAVE_STATUS_LEAD:
      return state.set('statusFilter', action.payload);

    case CLEAN_NEW_LEAD:
      return state.set('newLead', {
                                    created_at: null,
                                    first_name: '',
                                    last_name: '',
                                    origin: 'default',
                                    country: 'default',
                                    phone_one: '',
                                    city_interest: 'default',
                                    other_city: '',
                                    email: '',
                                    status: 'default',
                                    tracking: [{
                                      comment: "",
                                      action: "",
                                      formErrors: []
                                    },]
                                  });

    case SAVE_LEAD_EMAIL:
      localStorage.setItem('lead_email', action.payload.values);
      return state.set('lead_email', action.payload.values);

    case CLEAN_AFFILIATION: 
      localStorage.setItem('lead_email', '');
      return state.set('lead_email', '');

    default:
      return state
  }
}

export default leads;